import React, { useState, useEffect } from "react";
import { Link, navigateTo } from "gatsby";
import axios from "../../utils/request"
import Cookies from "js-cookie";
import { genImagePath } from "../../utils/genImagePath";
import style from "./index.module.css";

const PublisherHeader = ({
  pathName,
  parseFlag,
  isApply,
}) => {
  const [showAvatarBox, setShowAvatarBox] = useState(false);
  const [flag, setFlag] = useState(false);
  const [profile, setProfile] = useState({});
  const [endTime, setEndTime] = useState("");
  const [showNoticeBox, setShowNoticeBox] = useState(false);

  function handleLogOut() {
    Cookies.remove("token");
    Cookies.remove("userId");
    navigateTo("/");
  }

  useEffect(() => {
    async function getDetail() {
      const { data } = await axios({
        method: "get",
        url: "/designer/profile",
        headers: { "Content-Type": "application/json;charset=UTF-8" },
      });
      // console.log(data);
      if (data) setProfile(data);
    }

    async function getList() {
      const { data } = await axios({
        method: "get",
        url: "/designer/contract/list",
        headers: { "Content-Type": "application/json;charset=UTF-8" },
      });
      // console.log(data);
      if (data) setEndTime(data[0]?.endTime);
    }

    if (pathName?.indexOf("designerIndex") !== -1 ||
    pathName?.indexOf("selectClothes") !== -1 ||
    pathName?.indexOf("bill") !== -1) {
      getList();
      getDetail();
    }
    setFlag(parseFlag ? true : false);
  }, []);

  return (
    <div className={style.publishHeader}>
      <Link to={
        (pathName?.indexOf("designerIndex") !== -1 ||
        pathName?.indexOf("selectClothes") !== -1 ||
        pathName?.indexOf("bill") !== -1 ||
        (pathName?.indexOf("fullInfo") !== -1 &&
        flag)) ? '/designerPress/designerIndex' : '/' } className={style.publishIcon}>
        产品出版社
      </Link>
      {pathName?.indexOf("register") === -1 &&
        pathName?.indexOf("login") === -1 &&
        pathName?.indexOf("designerIndex") === -1 &&
        pathName?.indexOf("selectClothes") === -1 &&
        pathName?.indexOf("bill") === -1 &&
        !flag && (
          <ul className={style.designerMenu}>
            <li className={style.hasDropMenu}>
              {/* <div
              className={style.noticeWrap}
              onMouseEnter={() => setShowNoticeBox(true)}
              onMouseLeave={() => setShowNoticeBox(false)}
            >
              <div className={style.noticeIcon}>
                <span></span>
              </div>
              {showNoticeBox && (
                <ul className={style.noticeDrop}>
                  <li>
                    <h5>2020-10-10 12:00</h5>
                    <h4>
                      您的账号申请已经通过审核，请保持手机畅通，我们的BD专员将会尽快与您联系
                    </h4>
                  </li>
                  <li>
                    <h5>2020-10-12 12:00</h5>
                    <h4>您的合同即将到期，请及时联系</h4>
                  </li>
                </ul>
              )}
            </div> */}
              <span onClick={handleLogOut} className={style.login}>
                退出
              </span>
            </li>
          </ul>
        )}
      {(pathName?.indexOf("designerIndex") !== -1 ||
        pathName?.indexOf("selectClothes") !== -1 ||
        pathName?.indexOf("bill") !== -1) &&
        pathName?.indexOf("verify") === -1 && (
          <ul className={style.designerMenu}>
            <li>
              合同将于 {endTime?.split(" ")[0]?.split("-")?.join(".")}到期
            </li>
            {/* <li></li>
            <li>
              <Link>
                {pathName?.indexOf("bill") !== -1 && (
                  <>
                    <img
                      className={style.BArrR}
                      src={require("../../img/black_right.png")}
                      alt="箭头"
                    />
                    <img
                      className={style.BArrL}
                      src={require("../../img/black_left.png")}
                      alt="箭头"
                    />
                  </>
                )}
                <img
                  src={require("../../img/icon_money.png")}
                  alt="设计师账单"
                />
                对账单
              </Link>
            </li> */}
            <li></li>
            <li>
              <Link to="/designerPress/selectClothes">
                {pathName?.indexOf("selectClothes") !== -1 && (
                  <>
                    <img
                      className={style.BArrR}
                      src={require("../../img/black_right.png")}
                      alt="箭头"
                    />
                    <img
                      className={style.BArrL}
                      src={require("../../img/black_left.png")}
                      alt="箭头"
                    />
                  </>
                )}
                <img
                  src={require("../../img/icon_clothes.png")}
                  alt="设计师选款"
                  className={style.iconImg}
                />
                选款
              </Link>
            </li>
            <li></li>
            <li className={style.hasDropMenu}>
              {/* <div
              className={style.noticeWrap}
              onMouseEnter={() => setShowNoticeBox(true)}
              onMouseLeave={() => setShowNoticeBox(false)}
            >
              <div className={style.noticeIcon}>
                <span></span>
              </div>
              {showNoticeBox && (
                <ul className={style.noticeDrop}>
                  <li>
                    <h5>2020-10-10 12:00</h5>
                    <h4>
                      您的账号申请已经通过审核，请保持手机畅通，我们的BD专员将会尽快与您联系
                    </h4>
                  </li>
                  <li>
                    <h5>2020-10-12 12:00</h5>
                    <h4>您的合同即将到期，请及时联系</h4>
                  </li>
                </ul>
              )}
            </div> */}
              <div
                className={style.avatarWrap}
                onMouseEnter={() => setShowAvatarBox(true)}
                onMouseLeave={() => setShowAvatarBox(false)}
              >
                <div
                  style={{
                    background: profile?.baseInfo?.PRurl[0]?.url && `url(${genImagePath(profile?.baseInfo?.PRurl[0]?.url)}) no-repeat center`,
                  }}
                  className={style.avatarPhoto}>
                </div>
                {showAvatarBox && (
                  <ul className={style.avatarDrop}>
                    <img
                      src={require("../../img/black_arrow.png")}
                      alt="设计师下拉三角"
                    />
                    <li onClick={() => navigateTo('/designerPress/designerIndex')}>个人主页</li>
                    {/* <li>账号设置</li> */}
                    <li onClick={handleLogOut}>退出</li>
                  </ul>
                )}
              </div>
            </li>
          </ul>
        )}
      {flag && (
        <Link
          to={
            pathName?.indexOf("register") !== -1 ? "/designerPress/verify" : "/designerPress/designerIndex"
          }
          className={style.login}
        >
          返回
        </Link>
      )}
      {pathName?.indexOf("login") !== -1 && (
        <Link to="/designerPress/register" className={style.login}>
          注册
        </Link>
      )}
      {pathName?.indexOf("register") !== -1 && !isApply && (
        <Link to="/designerPress/login" className={style.login}>
          登录
        </Link>
      )}
    </div>
  );
};

export default PublisherHeader;
