import React from "react";
import Cookies from "js-cookie";
import TabTable from "../../../components/tab-table";
import PublisherHeader from "../../../components/publish-header";
import Cursor from "../../../components/cursor";
import { genImagePath } from "../../../utils/genImagePath";
import style from "./selectClothes.module.css";

const COMMON_COLUMNS = [
  {
    title: "图",
    dataIndex: "imgList",
    key: "imgList",
    width: 100,
    render: (value) => (
      <img
        src={genImagePath(value[0])}
        width="57"
        height="57"
        alt="icy商品图"
      />
    ),
  },
  {
    title: "商品名称",
    dataIndex: "name",
    key: "name",
  },
];

const TABS = [
  {
    value: "销售中",
    num: 2,
    columns: [
      ...COMMON_COLUMNS,
      {
        title: "发售日期",
        dataIndex: "saleDate",
        key: "saleDate",
        width: 150,
        render: (value) => (
          <span>{value ? value : "待定"}</span>
        ),
      },
      {
        title: "吊牌价",
        dataIndex: "hangTagPrice",
        key: "hangTagPrice",
        render: (value) => (
          <span>{value ? value : "待定"}</span>
        ),
      },
    ],
    filters: [{
      label: "发售日期",
      prop: ["saleStartDate", "saleEndDate"].join(),
      type: "dateRange",
      startPlaceholder: "开始日期",
      endPlaceholder: "结束日期",
    }, {
      label: "商品名称",
      prop: "name",
    }],
  },
  {
    value: "待发售",
    num: 1,
    columns: [
      ...COMMON_COLUMNS,
      {
        title: "预计发售日期",
        dataIndex: "preSaleDate",
        key: "preSaleDate",
        width: 200,
        render: (value) => (
          <span>{value ? value : "待定"}</span>
        ),
      },
    ],
    filters: [{
      label: "预计发售日期",
      prop: ["preSaleStartDate", "preSaleEndDate"].join(),
      type: "dateRange",
      startPlaceholder: "开始日期",
      endPlaceholder: "结束日期",
    }, {
      label: "商品名称",
      prop: "name",
    }],
  },
  {
    value: "已取消",
    num: 3,
    columns: [
      ...COMMON_COLUMNS,
    ],
    filters: [],
  },
];

export default function ({ location }) {
  return (
    <div className={style.registerWrap}>
      <Cursor></Cursor>
      <PublisherHeader pathName={location.pathname}></PublisherHeader>
      <div className={style.selectCloth}>
        <TabTable
          tabs={TABS}
          defaultTabVal={2}
          outerParams={{ userId: +Cookies.get("userId") }}
          url="/designer/goodsList"
          emptyText="暂无选款"
          tabWidth={45}
        >
          <div className={style.cancelWord}>*请联系工作人员取回样衣</div>
        </TabTable>
        {/* 已取消情况 */}
        {/* {isCancel && (
          <div className={style.cancelWord}>*请联系工作人员取回样衣</div>
        )} */}

      </div>
    </div>
  );
}
